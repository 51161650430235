import { useState, useEffect } from "react";
import { motion } from "framer-motion";

const messages = [
	"Aguarde um momento...",
	"Salvando o Chatbot...",
	"Estamos processando tudo...",
];

export default function FullScreenLoader() {
	const [messageIndex, setMessageIndex] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
		}, 3000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div
			style={{
				position: "fixed",
				top: "0",
				left: "0",
				width: "100%",
				height: "100%",
				backgroundColor: "rgba(0,0,0,.5)",
				zIndex: "9",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<motion.div
				key={messageIndex}
				initial={{ opacity: 0, y: 10 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: -10 }}
				transition={{ duration: 0.5 }}
				style={{
					color: "#fff",
					backgroundColor: "#607a87",
					padding: "12px",
					fontSize: "24px",
					borderRadius: "4px",
				}}
			>
				{messages[messageIndex]}
			</motion.div>
		</div>
	);
}
