import React, { useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";

const FileUploader = ({ file, setFile, msgMedia, setMsgMedia }) => {
	const [error, setError] = useState("");
	const [previewUrl, setPreviewUrl] = useState(msgMedia?.url);

	const handleFile = (selectedFile) => {
		if (
			selectedFile.type !== "application/pdf" &&
			!selectedFile.type.startsWith("image/") &&
			!selectedFile.type.startsWith("audio/") &&
			!selectedFile.type.startsWith("video/")
		) {
			setError(
				"Tipo de arquivo inválido. Somente arquivos PDF, imagens, áudio e vídeo são permitidos."
			);
			setFile(null);
			return;
		}

		const maxSize = 16 * 1024 * 1024;
		if (selectedFile.size > maxSize) {
			setError("O tamanho do arquivo excede 16 MB.");
			setFile(null);
			return;
		}

		setError("");
		setFile(selectedFile);
	};

	const handleDrop = (event) => {
		event.preventDefault();
		const droppedFiles = event.dataTransfer.files;
		if (droppedFiles && droppedFiles.length > 0) {
			handleFile(droppedFiles[0]);
		}
	};

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleChange = (event) => {
		const files = event.target.files;
		if (files && files.length > 0) {
			handleFile(files[0]);
		}
	};

	useEffect(() => {
		if (!file && msgMedia?.file) {
			setFile(msgMedia.file);
		}
		if (!file) {
			setPreviewUrl(null);
			return;
		}

		const url = URL.createObjectURL(file);

		setPreviewUrl(url);

		return () => {
			URL.revokeObjectURL(url);
		};
	}, [file]);

	useEffect(() => {
		if (!msgMedia.file && msgMedia.url) {
			setPreviewUrl(msgMedia.url);
		}
	}, [msgMedia]);

	const handleRemove = () => {
		setFile(null);
		setMsgMedia({});
		setError("");
	};

	return (
		<div>
			{/* Drag-and-drop area */}
			<div
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				style={{
					border: "2px dashed #ccc",
					borderRadius: "4px",
					padding: "20px",
					textAlign: "center",
					cursor: "pointer",
					marginBottom: "10px",
					position: "relative",
				}}
				onClick={(e) => {
					if (e.target.nodeName === "BUTTON") return;
					document.getElementById("fileInput").click();
				}}
			>
				Arraste e solte seu arquivo aqui ou clique para selecionar um arquivo.
				{(file || msgMedia?.url) && (
					<div>
						<p style={{ color: "green" }}>
							Arquivo selecionado: {msgMedia?.url ? msgMedia?.name : file.name}
						</p>
						<button
							onClick={handleRemove}
							style={{
								position: "absolute",
								right: "10px",
								top: "5px",
								cursor: "pointer",
								border: "none",
								background: "none",
							}}
						>
							<FaTrash style={{ pointerEvents: "none" }} />
						</button>
					</div>
				)}
			</div>

			<input
				type="file"
				id="fileInput"
				style={{ display: "none" }}
				onChange={handleChange}
			/>

			{error && <p style={{ color: "red" }}>{error}</p>}

			{previewUrl && (file || msgMedia?.url) && (
				<div style={{ marginTop: "10px" }}>
					{((msgMedia?.url && msgMedia?.type.startsWith("image/")) ||
						(file instanceof File && file.type.startsWith("image/"))) && (
						<img src={previewUrl} alt="Preview" style={{ maxWidth: "200px" }} />
					)}
					{((msgMedia?.url && msgMedia?.type === "application/pdf") ||
						(file instanceof File && file.type === "application/pdf")) && (
						<iframe
							src={previewUrl}
							title="PDF Preview"
							style={{ width: "200px", height: "200px", border: "none" }}
						/>
					)}
					{((msgMedia?.url && msgMedia?.type.startsWith("audio/")) ||
						(file instanceof File && file.type.startsWith("audio/"))) && (
						<audio controls src={previewUrl} style={{ width: "200px" }} />
					)}
					{((msgMedia?.url && msgMedia?.type.startsWith("video/")) ||
						(file instanceof File && file.type.startsWith("video/"))) && (
						<video controls src={previewUrl} style={{ maxWidth: "200px" }} />
					)}
				</div>
			)}
		</div>
	);
};

export { FileUploader };
